import React from "react";
import { Link, useLocation } from "react-router-dom";
import { useDocument } from "../firebase files/hooks/useDocument";
import "./NavbarBottom.css";
import { useNavigate } from "react-router-dom";
import { useAuthContext } from "../firebase files/hooks/useAuthContext";
export default function NavbarBottom() {
  const location = useLocation(); // Get the current location
  const { user } = useAuthContext();
  const navigate = useNavigate();

  const { document: appTypeDetails, error: appTypeDetailserror } = useDocument(
    "settings",
    "AppType"
  );
  const { document: NavMenuNames, error: NavMenuNameserror } = useDocument(
    "settings",
    "EditNavbarMenus"
  );

  const { document: pageControlDoc, error: pageControlDocError } = useDocument(
    "settings",
    "pagesControl"
  );

  const showProfile = () => {
    navigate("/profile");
  };

  const showHome = () => {
    navigate("/")
  };

  const showSecondPage = () => {
    {
      pageControlDoc && String(pageControlDoc.products) === "true" && String(appTypeDetails.apptype) !== "lite" ? (
        navigate("/services")
      ) :
        navigate("/gallery")
    }
  };

  const showThirdPage = () => {
    navigate("/customerdashboard");
  };

  const showFourthPage = () => {
    navigate("/contact");
  };

  const showAboutPage = () => {
    navigate("/about")
  }

  const showGallery = () => {
    navigate("/gallery")
  }

  //Menus as per role
  let firstMenuIcon = "";
  let firstMenu = "";
  let secondMenuIcon = "";
  let secondMenu = "";
  let thirdMenuIcon = "";
  let thirdMenu = "";
  let fourthMenu = "";
  let fourthMenuIcon = "";

  if (!user) {
    firstMenuIcon = "home";
    firstMenu = NavMenuNames && NavMenuNames.firstMenuName;
    secondMenuIcon = "local_mall";
    secondMenu = NavMenuNames && NavMenuNames.secondMenuName;
    thirdMenuIcon = "insert_chart";
    thirdMenu = "Dashboard";
    fourthMenuIcon = "perm_phone_msg";
    fourthMenu = NavMenuNames && NavMenuNames.thirdMenuName;
  } else {
    firstMenuIcon = "home";
    firstMenu = NavMenuNames && NavMenuNames.firstMenuName;
    secondMenuIcon = "local_mall";
    secondMenu = NavMenuNames && NavMenuNames.secondMenuName;
    thirdMenuIcon = "insert_chart";
    thirdMenu = "Dashboard";
    fourthMenuIcon = "perm_phone_msg";
    fourthMenu = NavMenuNames && NavMenuNames.thirdMenuName;
  }
  return (
    <div className="small navbar-mobile-bottom">
      <div className="navbar-mobile-bottom-menu" id="divBottomNavBar">
        <div
          className={`navbar-mobile-bottom-menu-a bm_single ${location.pathname === "/" ? "active_bottom_menu" : ""
            } `}
          style={{ display: "flex", flexDirection: "column" }}
          onClick={showHome}
        >
          <span className="material-symbols-outlined">{firstMenuIcon}</span>
          <small>{firstMenu}</small>
        </div>


        {pageControlDoc && String(pageControlDoc.products) === "true" && String(appTypeDetails.apptype) !== "lite" ? (
          <div
            className={`navbar-mobile-bottom-menu-a bm_single ${location.pathname === "/services" ? "active_bottom_menu" : ""
              } `}
            style={{ display: "flex", flexDirection: "column" }}
            onClick={showSecondPage}
          >
            <span className="material-symbols-outlined">{secondMenuIcon}</span>
            <small>{secondMenu}</small>
          </div>
        ) : (
          user && user ? (
            <div
              className={`navbar-mobile-bottom-menu-a bm_single ${location.pathname === "/customerdashboard" ? "active_bottom_menu" : ""
                } `}
              style={{ display: "flex", flexDirection: "column" }}
              onClick={showThirdPage}
            >
              <span className="material-symbols-outlined">insert_chart</span>
              <small>Dashboard</small>
            </div>
          ) : (
            <div
              className={`navbar-mobile-bottom-menu-a bm_single ${location.pathname === "/about" ? "active_bottom_menu" : ""
                } `}
              style={{ display: "flex", flexDirection: "column" }}
              onClick={showAboutPage}
            >
              <span className="material-symbols-outlined">import_contacts</span>
              <small>About Us</small>
            </div>
          )

        )}


        <div className="bmp_single">
          <Link
            to="/profile"
            className="profile_icon bm_single"
          >
            {user && user ? <img className="bottom-nav-profil-pic" src={user && user.photoURL} alt="user photo" />
              : (<span className="material-symbols-outlined">person</span>)}
          </Link>
        </div>



        {user && user ? (<div
          className={`navbar-mobile-bottom-menu-a bm_single ${location.pathname === "/about" ? "active_bottom_menu" : ""
            } `}
          style={{ display: "flex", flexDirection: "column" }}
          onClick={showAboutPage}
        >
          <span className="material-symbols-outlined">
            import_contacts
          </span>
          <small>About Us</small>
        </div>) : (<div
          className={`navbar-mobile-bottom-menu-a bm_single ${location.pathname === "/gallery" ? "active_bottom_menu" : ""
            } `}
          style={{ display: "flex", flexDirection: "column" }}
          onClick={showGallery}
        >
          <span className="material-symbols-outlined">
            image
          </span>
          <small>Gallery</small>
        </div>)
        }



        <div
          className={`navbar-mobile-bottom-menu-a bm_single ${location.pathname === "/contact" ? "active_bottom_menu" : ""
            } `}
          style={{ display: "flex", flexDirection: "column" }}
          onClick={showFourthPage}
        >
          <span className="material-symbols-outlined">{fourthMenuIcon}</span>
          <small>{fourthMenu}</small>
        </div>
      </div>
    </div>
  );
}
