import React, { useState, useRef, useEffect } from "react";
import "./CorporateTwoNumber.scss";
import { IoMdRestaurant } from "react-icons/io";
import { FaHotel } from "react-icons/fa6";
import { FaShoppingBasket } from "react-icons/fa";
import { GrUserWorker } from "react-icons/gr";
import { CgSmartHomeWashMachine } from "react-icons/cg";
import { LiaStoreSolid } from "react-icons/lia";
import { MdSentimentSatisfiedAlt } from "react-icons/md";
import { IoIosPlay } from "react-icons/io";
import CountUp from "react-countup";

const VideoModal = ({ isOpen, onClose }) => {
  if (!isOpen) return null;

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <button className="modal-close-button" onClick={onClose}>
          &times;
        </button>
        <div className="video-container">
          <video width="90%" height={400} controls autoPlay>
            <source
              src="https://firebasestorage.googleapis.com/v0/b/smart-wash-prod.appspot.com/o/videos%2Fsmart-wash-size.mp4?alt=media&token=9e46708d-8623-43af-aad1-05a3a4000720"
              type="video/mp4"
            />
          </video>
        </div>
      </div>
    </div>
  );
};

const CorporateTwoNumber = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isCounterVisible, setIsCounterVisible] = useState(false);
  const counterRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setIsCounterVisible(entry.isIntersecting);
      },
      { threshold: 0.1 }
    );

    if (counterRef.current) {
      observer.observe(counterRef.current);
    }

    return () => {
      if (counterRef.current) {
        observer.unobserve(counterRef.current);
      }
    };
  }, []);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <div className="corporateTwo-numbers-main">
      <div className="corporateTwo-numbers-main-container">
        <div className="corporateTwo-numbers-left-container">
          <div className="corporateTwo-numbers-left-img-container">
            <img
              src="https://firebasestorage.googleapis.com/v0/b/smart-wash-prod.appspot.com/o/images%2Fvideocover.webp?alt=media&token=a49b1a97-8b2d-44f1-b1a7-380bf54388c7"
              alt="an image related to milestones"
            />
          </div>
          <div
            className="corporateTwo-numbers-image-videoplay"
            onClick={openModal}
          >
            <IoIosPlay size={30} />
          </div>
        </div>

        <div className="corporateTwo-numbers-right-container">
          <div className="corporateTwo-numbers-right-info">
            <h1>Why choose us</h1>
            <h2>Best Laundry Service</h2>
            <p>
              With our Industrial Washing process , you will actually save a lot
              of resources while insuring your garments stay safe & hygiene An
              average human spends between 60L water consumption per load , 4.25
              Kwh electricity consumption per load , 100ml detergent per load .
              An average human spends between 12000 hrs of their adult life in
              cleaning & managing their cloth.
            </p>
          </div>

          <div className="corporateTwo-numbers-special-sectors">
            <div className="corporateTwo-numbers-sector">
              <FaShoppingBasket className="corporateTwo-home-sector-icon" />
              <p>Retail Laundry</p>
            </div>

            <div className="corporateTwo-numbers-sector">
              <FaHotel className="corporateTwo-home-sector-icon" />
              <p>Hotels Textile</p>
            </div>

            <div className="corporateTwo-numbers-sector">
              <IoMdRestaurant className="corporateTwo-home-sector-icon" />
              <p>Restaurant Textile</p>
            </div>

            <div className="corporateTwo-numbers-sector">
              <GrUserWorker className="corporateTwo-home-sector-icon" />
              <p>Workwear System</p>
            </div>
          </div>

          {/* <button>Read More</button> */}
        </div>
      </div>

      <div className="corporateTwo-numbers-main-counter" ref={counterRef}>
        <div className="corporateTwo-numbers-counter-container">
          <div className="corporateTwo-numbers-counter-cm">
            <CgSmartHomeWashMachine className="corporateTwo-numbers-counter-icon" />
            <div>
              <h5>
                <CountUp
                  end={120}
                  suffix="M"
                  duration={3}
                  start={isCounterVisible ? 0 : null}
                />
              </h5>
              <span>Garments processed</span>
            </div>
          </div>

          <div className="corporateTwo-numbers-counter-cm">
            <LiaStoreSolid className="corporateTwo-numbers-counter-icon" />
            <div>
              <h5>
                <CountUp
                  end={5}
                  suffix="+"
                  duration={5}
                  start={isCounterVisible ? 0 : null}
                />
              </h5>
              <span>Branches in India</span>
            </div>
          </div>

          <div className="corporateTwo-numbers-counter-cm">
            <MdSentimentSatisfiedAlt className="corporateTwo-numbers-counter-icon" />
            <div>
              <h5>
                <CountUp
                  end={100}
                  suffix="%"
                  duration={3}
                  start={isCounterVisible ? 0 : null}
                />
              </h5>
              <span>Customer Ratings</span>
            </div>
          </div>
        </div>
      </div>

      <VideoModal isOpen={isModalOpen} onClose={closeModal} />
    </div>
  );
};

export default CorporateTwoNumber;
