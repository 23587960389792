import React from "react";
import "../corporateTwoCss/corporateTwoStyle.scss";
import { Routes, Route } from "react-router-dom";
import CorporateTwoHome from "../corporateTwoPages/CorporateTwoHome";
import GlobalRoute from "../../../../global route/GlobalRoute";
import Navbar from "../../../../default components/Navbar";
import BusinessThreeNavbar from "../../../business/businessThree/businessThreeComponents/BusinessThreeNavbar";
import CorporateTwoTerms from "../corporateTwoPages/CorporateTwoTerms";
import CorporateTwoPolicy from "../corporateTwoPages/CorporateTwoPolicy";
import CorporateTwoFooter from "../corporateTwoComponents/CorporateTwoFooter/CorporateTwoFooter";
// import PGProfile from "../../../../default pages/profile/PGProfile";
import { useDocument } from "../../../../firebase files/hooks/useDocument";
import { Navigate } from "react-router-dom";
import PGBusinessThreeProfile from "../../../business/businessThree/businessThreePages/PGBusinessThreeProfile";

import { useAuthContext } from "../../../../firebase files/hooks/useAuthContext";
import PGCorporateTwoAbout from "../corporateTwoPages/PGCorporateTwoAbout";
import PGCorporateTwoCards from '../corporateTwoPages/PGCorporateTwoCards';
const CorporateTwoRoute = () => {
  const { user } = useAuthContext();
  const { document: navTypeDoc } = useDocument("settings", "navbarType");

  return (
    <>
      {navTypeDoc && navTypeDoc.activeNav == "FixNav" ? (
        <Navbar />
      ) : (
        <BusinessThreeNavbar />
      )}

      <Routes>
        <Route path="/" element={<CorporateTwoHome />} />
        <Route path="/about" element={<PGCorporateTwoAbout/>}/>
        <Route path="/term-condition" element={<CorporateTwoTerms />} />
        <Route path="/privacy-policy" element={<CorporateTwoPolicy />} />
        <Route path="/cardsdesign" element={<PGCorporateTwoCards/>}/>
        <Route
            path="/profile"
            element={<PGBusinessThreeProfile />}
          />      </Routes>
      <GlobalRoute />
      <CorporateTwoFooter />
    </>
  );
};

export default CorporateTwoRoute;
