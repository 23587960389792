import React from "react";
import { Route, Routes } from "react-router-dom";

// import css 
import '../educationTwoCss/EducationTwoColor.scss'
import '../educationTwoCss/EducationTwoFont.scss'
import '../educationTwoCss/EducationTwoStyle.scss'

// import pages
import EducationTwoHome from "../educationTwoPages/EducationTwoHome";

const EducationTwoRoute = () => {  
  return (
    <div className="education_two">               
      <Routes>
        <Route path="/" element={<EducationTwoHome/>}></Route>        
      </Routes>        
    </div>
  )
}

export default EducationTwoRoute;































// this template is taken from 
// https://preview.themeforest.net/item/educate-university-online-courses-school-education-template/full_screen_preview/49606280?_ga=2.226409818.1736213968.1726109803-1924452171.1721566110


