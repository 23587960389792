import React from "react";
import { Link } from "react-router-dom";

// import css
import "./EducationTwoComponentBanner.scss";

const EducationTwoComponentBanner = () => {
  return (
    <section
      className="et_banner"
      style={{
        backgroundImage: "url('./assets/img/educationtwo/herobg.jpg')",
      }}
    >
      {" "}
      <div className="shape_bottom">
        <img src="./assets/img/educationtwo/hero_right_bg_one.png" alt="" />
      </div>
      <div className="container">
        <div className="row">
          <div className="col-lg-6">
            <div className="et_banner_left">
              <div className="banner_left_inner">
                <h2>
                  Develop{" "}
                  <span className="relative">
                    Your
                    <span class="title-shape">
                      <svg
                        width="150"
                        height="65"
                        viewBox="0 0 168 65"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        className="highligt_text"
                      >
                        <path
                          d="M73.3761 8.49147C78.4841 6.01353 82.5722 4.25154 88.8933 3.3035C94.2064 2.50664 99.6305 2.0701 104.981 1.94026C120.426 1.56549 135.132 4.90121 146.506 9.70405C158.628 14.8228 166.725 22.5638 166.074 31.6501C165.291 42.5779 151.346 51.7039 133.508 56.8189C110.253 63.4874 81.7065 63.8025 58.5605 60.8285C37.5033 58.123 11.6304 51.7165 3.58132 40.0216C-3.43085 29.8337 12.0728 18.1578 27.544 11.645C40.3656 6.24763 55.7082 2.98328 70.8043 4.08403C81.9391 4.89596 93.2164 6.87822 102.462 9.99561C112.874 13.5066 120.141 18.5932 127.862 23.6332"
                          stroke="#0AB99D"
                          stroke-width="3"
                          stroke-linecap="round"
                        ></path>
                      </svg>
                    </span>
                  </span>{" "}
                  skills with our platform
                </h2>

                <p className="theme_description">
                  Find a Tutor, Tuition Center or Hobby Activities</p>
                  <p className="theme_des_inside">(Nursery, Kindergarten and Classes 1-12)</p>
                
                <div className="btn_video_area">
                  <div className="theme_btn btn_fill with_icon">
                    Explore All Courses
                    <span class="material-symbols-outlined">
                      arrow_right_alt
                    </span>
                  </div>
                  <div className="video_area">
                    <div className="video_icon">
                      <span class="material-symbols-outlined">play_arrow</span>
                    </div>
                    <Link className="click_link">
                    Watch Now
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="et_banner_right">
              <img
                src="./assets/img/educationtwo/hero_right_img.png"
                alt=""
                className="main_img"
              />

              <div className="shape_top">
                <img
                  src="./assets/img/educationtwo/hero_right_bg_two.png"
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default EducationTwoComponentBanner;
