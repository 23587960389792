import React from "react";

import "./EducationTwoAboutUs.scss";

const EducationTwoAboutUs = () => {
  return (
    <section className="et_aboutus sect_padding">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-6">
            <div className="eta_left">
              <img
                src="./assets/img/educationtwo/about_main_img.jpg"
                alt=""
                className="main_img"
              />
              <img
                src="./assets/img/educationtwo/about_img_top.jpg"
                alt=""
                className="img_top"
              />
              <img
                src="./assets/img/educationtwo/about_img_bottom.jpg"
                alt=""
                className="img_bottom"
              />
              <img
                src="./assets/img/educationtwo/about_shape.png"
                alt=""
                className="shape_img"
              />
            </div>
          </div>
          <div className="col-lg-6">
            <div className="eta_right">
              <div className="sect_header">
                <span className="sect_badge">OUr about us</span>
                <h3 className="sect_title">
                  <div className="h3_inner">
                    Learn & Grow your{" "}
                    <span className="relative">
                      skills
                      <span class="title-shape">
                        <svg
                          width="150"
                          height="65"
                          viewBox="0 0 168 65"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          className="highligt_text"
                        >
                          <path
                            d="M73.3761 8.49147C78.4841 6.01353 82.5722 4.25154 88.8933 3.3035C94.2064 2.50664 99.6305 2.0701 104.981 1.94026C120.426 1.56549 135.132 4.90121 146.506 9.70405C158.628 14.8228 166.725 22.5638 166.074 31.6501C165.291 42.5779 151.346 51.7039 133.508 56.8189C110.253 63.4874 81.7065 63.8025 58.5605 60.8285C37.5033 58.123 11.6304 51.7165 3.58132 40.0216C-3.43085 29.8337 12.0728 18.1578 27.544 11.645C40.3656 6.24763 55.7082 2.98328 70.8043 4.08403C81.9391 4.89596 93.2164 6.87822 102.462 9.99561C112.874 13.5066 120.141 18.5932 127.862 23.6332"
                            stroke="#0AB99D"
                            stroke-width="3"
                            stroke-linecap="round"
                          ></path>
                        </svg>
                      </span>
                    </span>{" "}
                    From anywhere
                  </div>
                </h3>
              </div>
              <p className="theme_description">
                Our mission is to list the Best Tutors, Tuition Centers and Kids
                Events and Classes. Tutortot is an Education City.
              </p>
              <div className="more_info">
                <div className="mi_left">
                  <h5>Exceptional tutors.</h5>
                  <p className="theme_description">
                    Exceptional tutors. We only accept the best from thousands
                    of applicants, so you can choose from the widest range of
                    qualified experts.
                  </p>
                </div>
                <div className="mi_right">
                  <h5>Variety of Subjects</h5>
                  <p className="theme_description">
                    PreSchool, Nursery, Kindergarten. Algebra, Calculus and
                    Statistics to English, Chemistry and Physics. You name it,
                    we list them.
                  </p>
                </div>
              </div>
              <div className="theme_btn btn_fill with_icon">
                More About Us
                <span class="material-symbols-outlined">arrow_right_alt</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default EducationTwoAboutUs;
