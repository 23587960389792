import { Route, Routes, Navigate } from "react-router-dom";
import { useAuthContext } from "../firebase files/hooks/useAuthContext";
import { useEffect, useState } from "react";
// components
import More from "../default components/More";
import NavbarBottom from "../default components/NavbarBottom";
import PartnerSlider from "../default components/PartnerSlider";
import Notification from "../default pages/notifications/Notification";
import ColorsComponent from "../default components/ColorsComponent";
import PGLoginPhone from "../firebase files/login/PGLoginPhone";
// admin
import PGAdminDashboard from "../allRoles/admin/PGAdminDashboard";
import PGAdminUser from "../allRoles/admin/PGAdminUser";
import AppSettings from "../allRoles/admin/PGAppSettings/AppSettings";

import AddPhoto from "../firebase files/AddPhoto";
import AddDocument from "../firebase files/AddDocument";
import ProductDetails from "../default pages/products details/ProductDetails";
import BookingList from "../default pages/bookings/BookingList";
import TicketDetail from "../chatboard/TicketDetail";
import CreateTicket from "../chatboard/CreateTicket";

// styles
import "../App.css";
import AdminSettings from "../allRoles/admin/AdminSettings";
import BasicAddBooking from "../default pages/add booking/BasicAddBooking";
import CustomerDashboard from "../allRoles/customer/dashboard/CustomerDashboard";
import PGServices from "../default pages/products/PGProducts";
import PGFaq from "../default pages/faq/PGFaq";
import PrivacyPolicy from "../default pages/privacy policy/PrivacyPolicy";
import ReturnPolicy from "../default pages/return policy/ReturnPolicy";
import PGAboutUs from "../default pages/about us/PGAboutUs";
import TermsAndCondition from "../default pages/terms & condition/TermsAndCondition";
import ContactUs from "../default pages/contact us/ContactUs";
import { useDocument } from "../firebase files/hooks/useDocument";
import PGGallery from "../default pages/gallery/PGGallery";
import FloatingSpeedDial from "../default components/FloatingSpeedDial";
import PGCareer from "../default pages/career/PGCareer";
import PGFeatures from "../default pages/features/PGFeatures";
import TemplateEditSidePopup from "../default components/TemplateEditSidePopup";
import QRScannerComponent from "../default components/QRScannerComponent";
import PGDashboard from "../allRoles/dashboards/PGDashboard";

function GlobalRoute() {
  const { user } = useAuthContext();
  const upcomingBookings = 6;
  const confirmedBookings = 100;
  const cancelledBookings = 2;

  const [currentModeStatus, setCurrentModeStatus] = useState("dark");

  const { document: dbTextContentDocuments, error: dbTextContentError } =
    useDocument("settings", "PWA");

  const { document: dbDisplayModeDocuments, error: dbDisplayModeError } =
    useDocument("settings", "mode");

  const { document: dbLaunchingSoonDocuments, error: dbLaunchingSoonError } =
    useDocument("settings", "launchingPage");

  const { document: dbPagesControlDocuments, error: dbPagesControlError } =
    useDocument("settings", "pagesControl");

  const { document: loginPopupDocuments, error: loginPopupError } = useDocument(
    "settings",
    "LoginPopup"
  );

  const { document: dbAppTypeDocuments, error: dbAppTypeError } = useDocument(
    "settings",
    "AppType"
  );

  const { document: dbTemplatesDocuments, error: dbTemplatesError } =
    useDocument("settings", "templates");

  // const [isLoading, setIsLoading] = useState(true);
  const [activeTemplate, setActiveTemplate] = useState(null);

  useEffect(() => {
    const fetchActiveTemplate = async () => {
      try {
        // Access the activeTemplate value from the fetched document
        if (dbTemplatesDocuments) {
          setActiveTemplate(dbTemplatesDocuments.activeTemplate);
        }
      } catch (error) {
        console.error("Error fetching active template:", error);
      } finally {
        // setIsLoading(false);
      }
    };

    fetchActiveTemplate();
  }, [dbTemplatesDocuments]);

  // PWA CODE START
  const [deferredPrompt, setDeferredPrompt] = useState(null);

  function startChromeInstall() {
    if (deferredPrompt) {
      deferredPrompt.prompt();
    }
    setinstallPopupDiv(false);
  }

  const [installPopupDiv, setinstallPopupDiv] = useState(true);
  const closeInstallPopup = () => {
    setinstallPopupDiv(false);
  };
  // PWA CODE END

  useEffect(() => {
    //First time set display mode from db to useState variable and also store the same value in localStorage
    if (
      localStorage.getItem("mode") === null ||
      localStorage.getItem("mode") === "null" ||
      localStorage.getItem("mode") === ""
    ) {
      // console.log('dbdisplaymode: ', dbDisplayModeDocuments && dbDisplayModeDocuments.displayMode)
      setCurrentModeStatus(
        dbDisplayModeDocuments && dbDisplayModeDocuments.displayMode
      );
      localStorage.setItem(
        "mode",
        dbDisplayModeDocuments && dbDisplayModeDocuments.displayMode
      );
    }
    // Function to handle changes in localStorage
    const handleStorageChange = () => {
      const storedValue = localStorage.getItem("mode");
      setCurrentModeStatus(storedValue);
    };

    // Call the function initially
    handleStorageChange();

    // Listen for changes in localStorage
    window.addEventListener("storage", handleStorageChange);

    // Cleanup the event listener
    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, [dbDisplayModeDocuments]);

  return (
    <>
      {/* <Navbar /> */}
      {/* <TemplateEditSidePopup /> */}
      {/* <QRScannerComponent/> */}

      <div className={"full-content"}>
        <Routes>
          <Route path="/adminsettings" element={<AdminSettings />}></Route>

          <Route path="/dashboard" element={<PGDashboard />}></Route>

          <Route
            path="/customerdashboard"
            element={
              <CustomerDashboard
                upcomingBookings={upcomingBookings}
                confirmedBookings={confirmedBookings}
                canceledBookings={cancelledBookings}
              />
            }
            // element={
            //   user && user ? (
            //     <CustomerDashboard
            //       upcomingBookings={upcomingBookings}
            //       confirmedBookings={confirmedBookings}
            //       canceledBookings={cancelledBookings}
            //     />
            //   ) : (
            //     <Navigate to="/login" />
            //   )
            // }
          />
          <Route
            path="/notifications"
            element={
              (user &&
                user.status === "active" &&
                dbAppTypeDocuments &&
                dbAppTypeDocuments.apptype === "liteplus") ||
              (dbAppTypeDocuments && dbAppTypeDocuments.apptype === "pro") ? (
                <Notification />
              ) : (
                <Navigate to="/login" />
              )
            }
          ></Route>
          <Route path="/services" element={<PGServices />} />
          <Route
            path="/product-details/:productId"
            element={<ProductDetails />}
          />

          <Route
            path="/career"
            element={
              dbPagesControlDocuments &&
              dbPagesControlDocuments.career === true && <PGCareer />
            }
          ></Route>
          <Route
            path="/features"
            element={
              dbPagesControlDocuments &&
              dbPagesControlDocuments.features === true && <PGFeatures />
            }
          ></Route>

          <Route
            path="/aboutus"
            element={<PGAboutUs />}
          ></Route>

          {/* <Route
            path="/admindashboard"
            element={
              user && user.role === "admin" ? (
                <PGAdminDashboard />
              ) : (
                <Navigate to="/login" />
              )
            }
          ></Route> */}
          <Route
            path="/sitesettings"
            element={
              user && user.role === "admin" ? (
                <AppSettings />
              ) : (
                <Navigate to="/login" />
              )
            }
          ></Route>
          <Route
            path="/adminuser"
            element={
              user && user.role === "admin" ? (
                <PGAdminUser />
              ) : (
                <Navigate to="/login" />
              )
            }
          ></Route>
          <Route
            path="/addphoto"
            element={
              user && user.role === "admin" ? (
                <AddPhoto />
              ) : (
                <Navigate to="/login" />
              )
            }
          ></Route>
          <Route
            path="/adddocument"
            element={
              user && user.role === "admin" ? (
                <AddDocument />
              ) : (
                <Navigate to="/login" />
              )
            }
          ></Route>

          <Route path="/returnpolicy" element={<ReturnPolicy />}></Route>
          <Route path="/contact" element={<ContactUs />}></Route>
          <Route
            path="/ticketdetail"
            element={
              (user &&
                user.status === "active" &&
                dbAppTypeDocuments &&
                dbAppTypeDocuments.apptype === "liteplus") ||
              (dbAppTypeDocuments && dbAppTypeDocuments.apptype === "pro") ? (
                <TicketDetail />
              ) : (
                <Navigate to="/login" />
              )
            }
          ></Route>
          <Route
            path="/createticket"
            element={
              user && user.status === "active" ? (
                <CreateTicket />
              ) : (
                <Navigate to="/login" />
              )
            }
          ></Route>
          <Route
            path="/bookinglist"
            element={user ? <BookingList /> : <Navigate to="/login" />}
          ></Route>
          <Route path="/gallery" element={<PGGallery />}></Route>
          <Route path="/faq" element={<PGFaq />}></Route>
          <Route path="/partnerslider" element={<PartnerSlider />}></Route>
          <Route path="/more" element={<More />}></Route>

          <Route
            path="/login"
            // element={<PGLoginPhone />}
            element={<PGLoginPhone />}
          ></Route>

          <Route
            path="/basicaddbooking/:productId"
            element={user ? <BasicAddBooking /> : <PGLoginPhone />}
          ></Route>
        </Routes>
      </div>
      <NavbarBottom />
      {/* <FooterUpper /> */}
      <FloatingSpeedDial />
      {/* <Footer /> */}
      <ColorsComponent />
    </>
  );
}

export default GlobalRoute;
