import React from "react";
import "./EducationTwoWorkFlow.scss";

const EducationTwoWorkFlow = () => {
  return (
    <div className="et_work-flow sect_padding">
      <div className="container">
        <div className="et_work_flow_inner">
          {/* Header Section */}
          <div className="et_work_flow_inner_header">
            <div className="et_work_flow_inner_header_content">
              <h2 className="et_work_title">
                Get Your path in Simple 4 Steps
              </h2>
            </div>
          </div>

          {/* Workflow Step Section */}
          <div className="et_work_flow_inner_bottom">
            <div className="et_work_flow_inner_bottom_inner">
              {/* Step 1 */}
              <div className="et_work_circle">
                <div className="et_work_circle_img">
                  <img
                    src="https://img.freepik.com/free-vector/hands-holding-tablet-with-forefinger-clicking-start-button-new-application-launch-flat-illustration_74855-20595.jpg?t=st=1726311372~exp=1726314972~hmac=6713688df304109f30c54df77a4eb8c09c774cb0cd0502b781b2419d169233a0&w=740"
                    alt="Step 1"
                  />
                </div>
                <div className="et_work_circle_content">
                  <h2 className="et_work_circle_head">Start Your Search</h2>
                  <p className="et_work_circle_para">
                    Search for online tutoring. Need help with your search? Request a tutor and we’ll have tutors contact you very soon!
                  </p>
                </div>
                <div className="et_work_circle_img_abso">
                  <img
                    src="https://tse1.mm.bing.net/th?id=OIP.sHaefRVBJE8zYZa-WIA-4gHaHa&pid=Api&P=0&h=220"
                    alt="Icon 1"
                  />
                </div>
              </div>

              {/* Step 2 */}
              <div className="et_work_circle">
                <div className="et_work_circle_img">
                  <img
                    src="https://img.freepik.com/free-vector/client-review-web-screen-successful-4-4-star-business-opinion_1150-37483.jpg?w=996&t=st=1726311249~exp=1726311849~hmac=b4f2fecc0c24f6e849a6403f52ed142b69097b069f300ab51db688c67d2d4f22"
                    alt="Step 2"
                  />
                </div>
                <div className="et_work_circle_content">
                  <h2 className="et_work_circle_head">Review</h2>
                  <p className="et_work_circle_para">
                    Read feedback and ratings from parents and students. Detailed tutor profiles also include photos more.
                  </p>
                </div>
                <div className="et_work_circle_img_abso">
                  <img
                    src="https://tse1.mm.bing.net/th?id=OIP.frudcSw39Hwz3Q0VkiqxmAHaHa&pid=Api&P=0&h=220"
                    alt="Icon 2"
                  />
                </div>
              </div>

              {/* Step 3 */}
              <div className="et_work_circle">
                <div className="et_work_circle_img">
                  <img
                    src="https://img.freepik.com/free-vector/schedule-concept-illustration_114360-1531.jpg?t=st=1726311645~exp=1726315245~hmac=4a7cb053a110ae8109b5a4b693d95203ace3f8efc95b7dd4d2ba4b2196fc3459&w=740"
                    alt="Step 3"
                  />
                </div>
                <div className="et_work_circle_content">
                  <h2 className="et_work_circle_head">Schedule</h2>
                  <p className="et_work_circle_para">
                    Communicate directly with tutors to find a time that works for you.
                  </p>
                </div>
                <div className="et_work_circle_img_abso">
                  <img
                    src="https://tse2.mm.bing.net/th?id=OIP.EguMGTUK1Zy4mQh-q0XzQAHaHa&pid=Api&P=0&h=220"
                    alt="Icon 3"
                  />
                </div>
              </div>

              {/* Step 4 */}
              <div className="et_work_circle">
                <div className="et_work_circle_img">
                  <img
                    src="https://img.freepik.com/free-vector/learning-concept-landing-page_23-2147777592.jpg?t=st=1726311528~exp=1726315128~hmac=4c1b2d3f87aa8cd3b43b5d76eef92bd06a3bcdd54c5a615f847fdbced74ea76b&w=740"
                    alt="Step 4"
                  />
                </div>
                <div className="et_work_circle_content">
                  <h2 className="et_work_circle_head">Start Learning</h2>
                  <p className="et_work_circle_para">
                    One-on-one instruction is the most effective way to learn.
                  </p>
                </div>
                <div className="et_work_circle_img_abso">
                  <img
                    src="https://tse2.mm.bing.net/th?id=OIP.t_HHlBtrwwVud79nfp0rbwAAAA&pid=Api&P=0&h=220"
                    alt="Icon 4"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EducationTwoWorkFlow;
