import React from "react";
import { Link } from "react-router-dom";

import "./EducationTwoJoinSession.scss";

const EducationTwoJoinSession = () => {
  return (
    <section
      className="et_joinsession sect_padding"
      style={{
        backgroundImage: "url('./assets/img/educationtwo/session_bg.jpg')",
      }}
    >
      <img
        src="./assets/img/educationtwo/join_shape_one.png"
        alt=""
        className="shape_one shape"
      />
      <img
        src="./assets/img/educationtwo/join_shape_two.png"
        alt=""
        className="shape_two shape"
      />
      <div className="container">
        <div className="etj_inner">
          <div className="row align-items-center">
            <div className="col-lg-8">
              <div className="etj_left">
                <h6>Join Our platform</h6>
                <h4>Call us at 82890 92379 for more information.
                </h4>
                <div className="theme_btn btn_fill with_icon">
                  Join With Us
                  <span class="material-symbols-outlined">arrow_right_alt</span>
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="etj_right">
                <div className="video_area text-center">
                  <div className="video_icon">
                    <span class="material-symbols-outlined">play_arrow</span>
                  </div>
                  <Link className="click_link">Watch Now</Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default EducationTwoJoinSession;
