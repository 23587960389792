import React from 'react';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import './EducationTwoTestimonials.scss'; // Your custom styles

const EducationTwoTestimonials = () => {
  const options = {
    loop: true,
    margin: 30,
    nav: false,
    dots: true,
    center: true,
    autoplay: false,
    autoplayTimeout: 5000,
    smartSpeed: 1000,
    responsive: {
      0: {
        items: 1,
      },
      600: {
        items: 1,
      },
      1000: {
        items: 3,
      },
    },
  };

  return (
    <section
    className="et_Testimonials_bg_img"
    style={{
      backgroundImage: "url('')", // Direct path to the image
    }}
  >
  <div className="et_Testimonials_inner">
    <div className="et_Testimonials_inner_content">
   <div className="et_Testimonials_inner_left">
    <div className="et_Testimonials_inner_left_content">
      <div className="et_Testimonials_Title">
        <span>/why students love us</span>
      </div>
   <h2 className="et_Testimonials_subTitle">What They are Talking About TutorTot.</h2>

    </div>

     </div>
    <div className="et_Testimonials_inner_right">
      <div className="et_Testimonials_inner_right_content">
        <div className="et_Testimonials_cards">
          <p className="et_Testimonials_cards_para">TutorTot has helped me find Tuitons with ease and i m very happy with them.The service is very good.I recommend it to all Tutors.
            </p>
          <h2 className="et_Testimonials_cards_name">kunika gera</h2>
          <text className="some_text">insightful</text>
          <div className="img_abso1">
            <img src="https://themes.envytheme.com/edali/wp-content/uploads/2020/02/user1.jpg" />
          </div>

        </div>
        <div className="et_Testimonials_cards">
          <p className="et_Testimonials_cards_para">I was very impressed by the company service. Lorem ipsum is simply free text used by copy typing refreshing. Neque porro est qui dolorem ipsum quia.
            </p>
          <h2 className="et_Testimonials_cards_name">kunika gera</h2>
          <text className="some_text">insightful</text>
          <div className="img_abso">
            <img src="https://themes.envytheme.com/edali/wp-content/uploads/2020/02/user1.jpg" />
          </div>

        </div>
      </div>
    </div>


</div>
</div>
</section>
  );
};

export default EducationTwoTestimonials;
