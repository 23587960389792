import React, { useState, useEffect } from "react";
import "./CorporateTwoProcess.scss";
import { Link } from "react-router-dom";

const serviceData = [
  {
    id: 1,
    serviceName: "Service Booking",
    serviceDescription:
      "Book a service from our platform from range of services",
    serviceImage: "https://firebasestorage.googleapis.com/v0/b/smart-wash-prod.appspot.com/o/images%2Fprocessp1.webp?alt=media&token=74394610-426b-4087-80b6-321933c20f25",
  },
  {
    id: 2,
    serviceName: "Clothes Pickup",
    serviceDescription:
      "Our agent will collect all items from your desired location and move to our facility",
    serviceImage: "https://firebasestorage.googleapis.com/v0/b/smart-wash-prod.appspot.com/o/images%2Fprocessp2.webp?alt=media&token=814fc981-4eef-4f2a-8542-3d7e41ba31e3",
  },
  {
    id: 3,
    serviceName: "Cleaning Process",
    serviceDescription:
      "We clean your products in our state of art laundry facility with eco friendly solutions",
    serviceImage: "https://firebasestorage.googleapis.com/v0/b/smart-wash-prod.appspot.com/o/images%2Fprocessp3.webp?alt=media&token=d294ef56-420d-4d2a-bc89-a7a3ac766837",
  },
  {
    id: 4,
    serviceName: "Packing & Delivery",
    serviceDescription:
      "Our agent will deliver all the items with gaurnteed quality and cleaned clothes",
    serviceImage: "https://firebasestorage.googleapis.com/v0/b/smart-wash-prod.appspot.com/o/images%2Fprocessp4.webp?alt=media&token=05e07a77-9e80-4317-bdf9-9a799a02875d",
  },
];

const CorporateTwoProcess = () => {
  const [activeStep, setActiveStep] = useState(1);
  const [progress, setProgress] = useState(0);
  const [animate, setAnimate] = useState(false);

  useEffect(() => {
    const interval = setInterval(() => {
      setProgress((prevProgress) => {
        if (prevProgress >= 100) {
          setActiveStep((prevStep) => {
            if (prevStep >= serviceData.length) {
              return 1; // Reset to the first step
            }
            return prevStep + 1;
          });
          setAnimate(true); // Trigger animation
          setTimeout(() => setAnimate(false), 500);
          return 0;
        }
        return prevProgress + 1;
      });
    }, 30);

    return () => clearInterval(interval);
  }, []);

  return (
    <div className="corporateTwo-home-process">
      <div className="corporateTwo-home-process-left">
        <div className="corporateTwo-home-process-left-heading">
          <h1>Cleaning Process</h1>
          <h2>Smartwash Cleaning Process</h2>
        </div>

        <div className="corporateTwo-home-process-left-info">
          <p>
            Our Service is dedicated to making your life easier by providing
            pick up laundry service. Give yourself one less thing to worry about
            and try our residential wash and fold service that includes pick up
            and delivery.
          </p>

          <p>
            We deliver the best in class complete laundry and dry cleaning
            services for multiple clients and various sectors such as sports,
            medical, industrial, hotels, and many more and we follow a thorough
            process of cleaning and delivering well-processed products and
            services
          </p>
        </div>

        <Link to="/contact">
          <button>Contact Us</button>
        </Link>
      </div>

      <div className="corporateTwo-home-process-right">
        <img
          src={serviceData[activeStep - 1].serviceImage}
          alt="service desc"
        />
        <div
          className={`corporateTwo-home-process-right-info ${
            animate ? "animate" : ""
          }`}
        >
          <div className="corporate-home-process-right-content">
            <h3>{serviceData[activeStep - 1].serviceName}</h3>
            <p>{serviceData[activeStep - 1].serviceDescription}</p>
          </div>

          <h1>0{serviceData[activeStep - 1].id}</h1>
        </div>

        <div className="corporateTwo-home-process-right-controller">
          <div className="corporateTwo-home-process-right-control-steps">
            {serviceData.map((service, index) => (
              <div
                key={service.id}
                className={`corporateTwo-home-process-step ${
                  index + 1 <= activeStep ? "active" : ""
                }`}
              >
                <h2>{service.id}</h2>
              </div>
            ))}
            <div className="corporateTwo-home-process-progress">
              <div
                className="corporateTwo-home-process-progress-bar"
                style={{
                  width: `${
                    ((activeStep - 1 + progress / 100) / serviceData.length) *
                    100
                  }%`,
                }}
              ></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CorporateTwoProcess;
