import React, { useEffect, useState } from "react";
import "./CorporateTwoBanner.scss";
import { useAuthContext } from "../../../../../firebase files/hooks/useAuthContext";
import { useFirestore } from "../../../../../firebase files/hooks/useFirestore";
import { useDocument } from "../../../../../firebase files/hooks/useDocument";
import { Link } from "react-router-dom";

const slides = [
  {
    id: 1,
    image:"https://firebasestorage.googleapis.com/v0/b/smart-wash-prod.appspot.com/o/images%2Fbanner1.webp?alt=media&token=111d5e08-a04d-4228-901a-9c0c912dd28c",
    title: "India's premier laundry and Drycleaning Services",
    subheading:
      "Best indian laundry service for all types of garments and fabrics with best in class service",
    text: "Learn more",
  },
  {
    id: 2,
    image:"https://firebasestorage.googleapis.com/v0/b/smart-wash-prod.appspot.com/o/images%2Fbanner2.webp?alt=media&token=6857af0b-100c-4c10-919a-0129df8b6a11",
    title: "Specialized Machinery and skilled experts",
    subheading:
      "Equipped with global standard machines and cleaning solution to deliver fresh and sparkling clothes, everytime!",
    text: "Get Started",
  },
  {
    id: 3,
    image:"https://firebasestorage.googleapis.com/v0/b/smart-wash-prod.appspot.com/o/images%2Fbanner3.webp?alt=media&token=a7e4c79f-d9a7-4b4c-a0a6-92c2e10caf23",
    title: "Factory Outlets & Industrial laundry",
    subheading:
      "No middle man , Very Low Prices ,Save Money , Better Cleaning, Hygiene Services",
    text: "Book Now",
  },
  {
    id: 4,
    image:"https://firebasestorage.googleapis.com/v0/b/smart-wash-prod.appspot.com/o/images%2Fbanner4.webp?alt=media&token=0ad2a15d-3ee6-4ff9-9378-489771da105f",
    title: "Multi Industry laundry services",
    subheading:
      "Corporate staff workwear - Uniform  & Personal protective workwear . Complete solutions for Hotels Laundry , Various Sectors of Industrial Garments laundry",
    text: "Get Quote",
  },
  {
    id: 5,
    image: "https://firebasestorage.googleapis.com/v0/b/smart-wash-prod.appspot.com/o/images%2Fbanner5.webp?alt=media&token=3d0b8eec-702d-4de5-bfbb-620ca962f124",
    title: "Workwear and Multi fabric laundry",
    subheading:
      "Service available for Sports,Medical,Hospitality,Commercial,Garments and many more",
    text: "Contact Us",
  },
];

const CorporateTwoBanner = () => {
  const { user } = useAuthContext();

  const [currentSlide, setCurrentSlide] = useState(0);
  const [bubbles, setBubbles] = useState([]);
  const [showAllBanners, setShowAllBanners] = useState(false);

  const { updateDocument, response } = useFirestore("settings");

  // BANNERS DOCUMENT
  const { document: dbBannersDocuments, error: dbBannersError } = useDocument(
    "settings",
    "banners"
  );

  // ACTIVE TEMPLATE DOCUMENT
  const { document: dbActiveTemplateDocuments, error: dbActiveTemplateError } =
    useDocument("settings", "templates");

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentSlide((prevBanner) => (prevBanner + 1) % slides.length);
    }, 7000);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    // Generate bubbles
    const newBubbles = Array.from({ length: 20 }, (_, i) => ({
      id: i,
      size: Math.random() * 50 + 10,
      left: Math.random() * 100,
      animationDuration: Math.random() * 10 + 5,
    }));
    setBubbles(newBubbles);
  }, []);

  //SET ALL BANNERS COMPONENTS START
  const bannerComponents = [
    {
      bannerUrl: "/assets/img/Screenshot 2024-08-13 143956.png",
      bannerName: "BusinessThreeBanners",
    },
    {
      bannerUrl: "/assets/img/Screenshot 2024-08-13 144245.png",
      bannerName: "BusinessOneBanner",
    },
    {
      bannerUrl: "/assets/img/Screenshot 2024-08-13 144116.png",
      bannerName: "CorporateTwoBanner",
    },
    {
      bannerUrl: "/assets/img/Screenshot 2024-08-13 144033.png",
      bannerName: "CorporateOneBanner",
    },
    {
      bannerUrl: "/assets/img/Screenshot 2024-08-13 144416.png",
      bannerName: "HomeHeroBanner",
    },
  ];

  const handleNoteStackClick = () => {
    setShowAllBanners(!showAllBanners);
  };
  const handleImageClick = async (clickedBannerName) => {
    console.log("Clicked Banner:", clickedBannerName);

    if (dbBannersDocuments) {
      const updatedBanners = dbBannersDocuments.banners.map((banner) => {
        if (banner.templateName === activeTemplate) {
          return { ...banner, activeBanner: clickedBannerName };
        } else {
          return banner;
        }
      });

      // Update the Firestore document
      try {
        await updateDocument("banners", { banners: updatedBanners });
        console.log("Banner updated successfully");
      } catch (error) {
        console.error("Error updating banner:", error);
      }
    }
  };
  let activeTemplate =
    dbActiveTemplateDocuments && dbActiveTemplateDocuments.activeTemplate;

  // SET ALL BANNERS COMPONENTS END

  return (
    <div className="corporateTwo-main-banner">
      {slides.map((slide, index) => (
        <div
          key={index}
          className={`corporateTwo-main-slide ${
            currentSlide === index ? "visible" : "hidden"
          }`}
          style={{ backgroundImage: `url(${slide.image})` }}
        >
          <div className="corporateTwo-main-slide-content">
            <h1 className="corporateTwo-main-banner-title">{slide.title}</h1>
            <Link to="/contact">
            <button className="corporateTwo-main-banner-button">
              {slide.text}
            </button>
            </Link>
          </div>
        </div>
      ))}

      <div className="bubbles-container">
        {bubbles.map((bubble) => {
          return (
            <div
              key={bubble.id}
              className="bubble"
              style={{
                width: `${bubble.size}px`,
                height: `${bubble.size}px`,
                left: `${bubble.left}%`,
                animationDuration: `${bubble.animationDuration}s`,
              }}
            ></div>
          );
        })}
      </div>

      <div className="currentSlideTracker">
        {slides.map((slide, index) => {
          return (
            <div
              key={index}
              className={`slide-track ${
                index === currentSlide ? "wide-tracker" : "round-tracker"
              }`}
              onClick={() => setCurrentSlide(index)}
            />
          );
        })}
      </div>
      {/* {showAllBanners && (
              <div className={`business-three-all-banners-container container ${showAllBanners ? '' : 'hide'}`}>
                <div className="row">
                  {bannerComponents.map((banner, index) => (
                    <div key={index} className="col-6 col-md-4 col-lg-3">
                      <img src={banner.bannerUrl} alt={banner.bannerName} onClick={() => handleImageClick(banner.bannerName)}
                        style={{ animationDelay: `${index * 0.2}s` }} 
                      />
                    </div>
                  ))}
                </div>
              </div>
            )}
        {user&&user.role==="admin"&&(
             <span class="material-symbols-outlined note-stack-icon" onClick={handleNoteStackClick}
           title="Change Banner Design"  >
               note_stack
             </span>
           )} */}
    </div>
  );
};

export default CorporateTwoBanner;
