import React from 'react'

// import component 
import EducationTwoComponentBanner from '../educationTwoComponents/EducationTwoComponentBanner'
import EducationTwoCategory from '../educationTwoComponents/EducationTwoCategory'
import EducationTwoCourses from '../educationTwoComponents/EducationTwoCourses'
import EducationTwoAboutUs from '../educationTwoComponents/EducationTwoAboutUs'
import EducationTwoJoinSession from '../educationTwoComponents/EducationTwoJoinSession'
import EducationTwoWhyUs from '../educationTwoComponents/EducationTwoWhyUs'
import EducationTwoTestimonials  from '../educationTwoComponents/EducationTwoTestimonials'
import EducationTwoFunctional  from '../educationTwoComponents/EducationTwoFunctional'
import EducationTwoDiscover from '../educationTwoComponents/EducationTwoDiscover'
import EducationTwoWorkFlow from '../educationTwoComponents/EducationTwoWorkFlow'


const EducationTwoHome = () => {
  return (
    <div>
        <EducationTwoComponentBanner/>
        <EducationTwoCategory/>.
        <EducationTwoCourses/>
        <EducationTwoAboutUs/>
        <EducationTwoJoinSession/>
        <EducationTwoWhyUs/>
        <EducationTwoTestimonials/>
        <EducationTwoWorkFlow/>
        
    </div>
  )
}

export default EducationTwoHome
