import React from 'react'
import CorporateTwoCards from '../corporateTwoComponents/CorporateTwoCards/CorporateTwoCards';

const PGCorporateTwoCards = () => {
    return (
        <div>
            <CorporateTwoCards/>
        </div>
    )
}

export default PGCorporateTwoCards;