import React from "react";
import "./Categories.scss";
import { useCollection } from "../firebase files/hooks/useCollection";
import { Link } from "react-router-dom";

const Categories = () => {
  const {
    documents: dbProductsCategoriesDocuments,
    error: dbProductsCategoriesError,
  } = useCollection("categories");

  const { documents: products, error: productsError } =
    useCollection("products");

  // Function to group documents by category and unique subcategories
  const groupByCategory = (documents, products) => {
    return documents.reduce((acc, doc) => {
      const category = doc.category;
      const subCategories = doc.subCategory; // Assuming subCategory is an array field name

      if (!subCategories || subCategories.length === 0) {
        return acc; // Skip categories with no subcategories
      }

      if (!acc[category]) {
        acc[category] = [];
      }

      subCategories.forEach((subCategoryItem) => {
        const subCategoryName = subCategoryItem.subCategoryName;

        // Check if there is at least one product with this category and subcategory
        const hasProduct = products.some(
          (product) =>
            product.category === category &&
            product.subCategory === subCategoryName
        );

        if (hasProduct) {
          // Check if subCategoryName already exists in this category
          const existingSubCategory = acc[category].find(
            (item) => item.subCategoryName === subCategoryName
          );

          if (!existingSubCategory) {
            // Push only if subCategoryName is not already added for this category
            acc[category].push({
              subCategoryName: subCategoryName,
              imgUrl: subCategoryItem.imgUrl, // Assuming imgUrl is an array field in subCategoryItem
              rating: subCategoryItem.rating, // Assuming rating is a field in subCategoryItem
            });
          }
        }
      });

      return acc;
    }, {});
  };

  const groupedCategories = dbProductsCategoriesDocuments
    ? groupByCategory(dbProductsCategoriesDocuments, products)
    : {};

  // Filter out categories with no subcategories
  const filteredCategories = Object.keys(groupedCategories).filter(
    (category) => groupedCategories[category].length > 0
  );

  return (
    <div className="category-component-parent-section">
      <div className="container">
        {filteredCategories.map((category) => (
          <div key={category} className="category-component-category-section">
            <div
              className="product-page-heading-title"
              style={{ marginTop: "30px" }}
            >
              <h2
                style={{
                  fontSize: "20px",
                  marginLeft: "0px",
                  color: "var(--heading-color)",
                  letterSpacing: "1px",
                  textTransform: "capitalize",
                }}
              >
                {category}
              </h2>
            </div>
            <br />
            <div className="category-component-category-cards">
              {groupedCategories[category].map((subcategoryItem, index) => (
                <Link
                  to={`/services?category=${encodeURIComponent(
                    category
                  )}&subcategory=${encodeURIComponent(
                    subcategoryItem.subCategoryName
                  )}`}
                  key={index}
                >
                  <div className="category-component-document-card">
                    <h3>{subcategoryItem.subCategoryName}</h3>
                    {subcategoryItem.imgUrl && (
                      <img
                        className="category-component-image-icon"
                        src={subcategoryItem.imgUrl}
                        alt={`${subcategoryItem.subCategoryName}-${index}`}
                      />
                    )}
                  </div>
                </Link>
              ))}
            </div>
          </div>
        ))}
        {dbProductsCategoriesError && <p>{dbProductsCategoriesError}</p>}
        {productsError && <p>{productsError}</p>}
      </div>
    </div>
  );
};

export default Categories;
