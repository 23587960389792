import React, { useEffect, useRef } from "react";
import "./BusinessThreePartners.scss";

const BusinessThreePartners = ({ id }) => {
  const headingRef = useRef(null);

  const partners = [
    "https://smart-laundry.developmentalphawizz.com/brand/1713966373.png",
    "https://smart-laundry.developmentalphawizz.com/brand/1713966313.png",
    "https://smart-laundry.developmentalphawizz.com/brand/1713966292.png",
    "https://smart-laundry.developmentalphawizz.com/brand/1713966268.png",
    "https://smart-laundry.developmentalphawizz.com/brand/1713966345.png",
  ];

  // CODE START FOR ANIMATION HEADING
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add("animate");
          } else {
            entry.target.classList.remove("animate");
          }
        });
      },
      { threshold: 0.1 }
    );

    if (headingRef.current) {
      observer.observe(headingRef.current);
    }

    return () => {
      if (headingRef.current) {
        observer.unobserve(headingRef.current);
      }
    };
  }, []);
  // CODE END FOR ANIMATION HEADING
  return (
    <div id={id}>
      <div className="medium_padding_div"></div>
      <div className="small_padding_div"></div>

      <div className="business-three-partners-container">
        <div className="container business-three-box-container">
          <div ref={headingRef} className="business-three-heading-div">
            <h3>
              Our trusted <span>Partners</span> network
            </h3>
          </div>
          <div className="business-three-template-view-more-icon">
            <span class="material-symbols-outlined">arrow_forward_ios</span>{" "}
          </div>
        </div>
        <div className="business-three-partners-marquee">
          <div className="business-three-partners-row">
            {partners.concat(partners).map((partner, index) => (
              <div
                key={index}
                className="business-three-partners-image-wrapper"
              >
                <img
                  src={partner}
                  alt={`Partner ${index + 1}`}
                  className="business-three-partners-image"
                />
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="large_padding_div"></div>
      <div className="large_padding_div"></div>
    </div>
  );
};

export default BusinessThreePartners;
