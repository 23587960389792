import React from "react";
import "./CorporateTwoCards.scss";
import { FaStar } from "react-icons/fa";

const CorporateTwoCards = () => {
  return (
    <div className="corporateTwo-cards-container">
      <div className="corporateTwo-card-1">
        <div className="card-1-image-container">
          <img
            src="https://imageio.forbes.com/specials-images/imageserve/66bcc543070b6a7e21f0fc74/US-TECH-APPLE-IPHONE/960x0.jpg?height=473&width=711&fit=bounds"
            alt="iphone 16"
          />
          <div className="card-1-active-status">
            <div className="card-1-active-flag"></div>
            <span>Active</span>
          </div>
        </div>

        <div className="card-1-info-container">
          <div className="corporatetwo-card-1-title-info">
            <h3>Iphone 16 Pro</h3>
            <div className="card-1-rating-info">
              <span>4.3</span>
              <FaStar color="#fca503" size={20} />
            </div>
          </div>
          <p>
            Iphone 16 pro is a new model launched by apple in 2024 with a18
            bionic chipset and AI features
          </p>
          <div className="card-1-important-info">
            <div className="card-1-price-info">
              <div className="card-1-discount-info">
                <p>
                  <del>$ 599</del>
                </p>
                <span>15% off</span>
              </div>
              <div className="card-1-selling-price">
                <h3>$ 500</h3>
              </div>
            </div>
            <button className="card-1-detail-btn">Details</button>
          </div>
        </div>
      </div>

      {/* <div className="corporateTwo-card-1">
        <div className="card-1-image-container">
          <img
            src="https://imageio.forbes.com/specials-images/imageserve/66bcc543070b6a7e21f0fc74/US-TECH-APPLE-IPHONE/960x0.jpg?height=473&width=711&fit=bounds"
            alt="iphone 16"
          />
          <div className="card-1-inactive-status">
            <div className="card-1-inactive-flag"></div>
            <span>Inactive</span>
          </div>
        </div>

        <div className="card-1-info-container">
          <div className="corporatetwo-card-1-title-info">
            <h3>Iphone 16 Pro</h3>
            <div className="card-1-rating-info">
              <span>4.3</span>
              <FaStar color="#fca503" size={20} />
            </div>
          </div>
          <p>
            Iphone 16 pro is a new model launched by apple in 2024 with a18
            bionic chipset and AI features
          </p>
          <div className="card-1-important-info">
            <div className="card-1-price-info">
              <div className="card-1-discount-info">
                <p>
                  <del>$ 599</del>
                </p>
                <span>15% off</span>
              </div>
              <div className="card-1-selling-price">
                <h3>$ 500</h3>
              </div>
            </div>
            <button className="card-1-detail-btn">Details</button>
          </div>
        </div>
      </div> */}

      <div className="corporateTwo-card-2">
        <div className="card-2-image-container">
          <img
            src="https://cdn.mos.cms.futurecdn.net/Pss7zC2NTf9it64A6rmEs-1200-80.jpg"
            alt="Pixel 9 Pro"
          />
          <div className="card-2-inactive-status">
            <div className="card-2-inactive-flag"></div>
            <span>Inactive</span>
          </div>

          <div className="card-2-rating-info">
            <span>4.5</span>
            <FaStar color="#fca503" size={20} />
          </div>
        </div>

        <div className="card-2-info-container">
          <div className="corporatetwo-card-2-title-info">
            <h3>Pixel 9 Pro</h3>
          </div>
          <p>
            Pixel 9 Pro is a high end smartphone from google with tensor chip for performance
          </p>
          <div className="card-2-important-info">
            <div className="card-2-price-info">
              <div className="card-2-discount-info">
                <p>
                  <del>$ 599</del>
                </p>
                <span>15% off</span>
              </div>
              <div className="card-2-selling-price">
                <h3>$ 500</h3>
              </div>
            </div>
            <button className="card-2-detail-btn">
              Details
              <span class="material-symbols-outlined" style={{border:"1px solid white",borderRadius:"50%"}}>chevron_forward</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CorporateTwoCards;
